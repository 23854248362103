import React, { useState } from "react";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const others_data = [
  {
    name: "PalettenWerk",
    items: [
      {
        text: "Informacja o realizowanej strategii podatkowej rok 2022",
        url: require("../assets/img/strategia_podatkowa_werk_2022.pdf"),
      },
    ],
  },
  {
    name: "Palko",
    items: [
      {
        text: "Informacja o realizowanej strategii podatkowej rok 2021",
        url: require("../assets/img/strategia_podatkowa_palko_2021.pdf"),
      },
      {
        text: "Informacja o realizowanej strategii podatkowej rok 2022",
        url: require("../assets/img/strategia_podatkowa_palko_2022.pdf"),
      },     
    ],
  },
];

const Others = () => {
  const lang = "pl";

  const [activeDepartment, setActiveDepartment] = useState(0);

  console.log(activeDepartment);
  return (
    <Layout
      seoTitle="Inne"
      lang={lang}
      // translationEN="/en/certificates/"
      // translationDE="/de/zertifikate/"
    >
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SmallBanner title="Inne" lang={lang} />
      <section className="others">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="others__tab-nav mb-50">
                {others_data?.map(({ name }, index) => (
                  <button
                    key={index}
                    onClick={() => setActiveDepartment(index)}
                    className={`btn btn--plain btn--center ${
                      activeDepartment === index ? "current" : ""
                    } `}
                  >
                    {name}
                  </button>
                ))}
              </div>
            </div>
            <div className="col-12">
              {others_data?.[activeDepartment]?.items?.map(
                ({ text, url }, index) => (
                  <div key={index} className="others__item">
                    <span>{text}</span>
                    <a
                      // href={require(url)}
                      href={url}
                      download
                      className="item__btn btn"
                      data-hover="Pobierz"
                    >
                      Pobierz
                      <span className="btn-arrows"></span>
                    </a>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Others;
